import http from '@/utils/request.js'

//注册
export const register = (params) => {
  return http.post('/n/system/register', params, { domainName: 'bpi' })
}
//获取验证码
export const valCode = (params) => {
  return http.post('/n/system/mobile/valCode/create', params, { domainName: 'bpi' })
}
// 登陆后获取验证码
export const loginValCode = (params) => {
  return http.post('/w/system/mobile/valCode', params, { domainName: 'bpi' })
}
//校验验证码
export const valid = (params) => {
  return http.post('/n/system/mobile/valCode/valid', params, { domainName: 'bpi' })
}
//登录
export const login = (params) => {
  return http.post('/n/system/webLogin', params, { domainName: 'bpi' })
}
//修改密码
export const retrievePwd = (params) => {
  return http.post('/n/system/webRetrievePwd', params, { domainName: 'bpi' })
}
// 获取用户信息
export const getUserInfo = (params) => {
  return http.post('/w/course/eduWebUser/personalCenter', params, { domainName: 'dpi' })
}
// 修改用户信息
export const setUserInfo = (params) => {
  return http.post('/w/course/eduWebUser/up', params, { domainName: 'dpi' })
}

// 首页轮播

export const getSwiperList = (params) => {
  return http.post('/w/system/cmsBanner/getList', params, { domainName: 'cpi' })
}

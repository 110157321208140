<template>
  <div class="course-container">
    <div class="course-container-title">个人信息</div>
    <a-form :model="userInfo" :style="{width:'550px', 'margin-left': '20px'}"
      auto-label-width class="login-form" :rules="rules"
      @submit="formSubmit"
    >
      <a-form-item field="phone" label="手机号">
        <a-input v-model="userInfo.phone" disabled placeholder="请输入手机号" />
      </a-form-item>    
      <a-form-item field="userName" label="用户名">
        <a-input v-model="userInfo.userName" disabled placeholder="请输入用户名" />
      </a-form-item>
      <!-- <a-form-item field="email" label="邮箱">
        <a-input v-model="userInfo.email" placeholder="请输入邮箱" />
      </a-form-item> -->
      <a-form-item field="_nickName" label="昵称">
        <a-input v-model="userInfo._nickName" placeholder="请输入昵称" />
      </a-form-item>
      <a-form-item field="name" label="姓名">
        <a-input v-model="userInfo.name" placeholder="请输入姓名" />
      </a-form-item>
      <a-form-item field="sex" label="性别">
        <a-select v-model="userInfo.sex" :options="sexList" :style="{width:'100%'}" placeholder="请选择" allow-clear />
      </a-form-item>
      <a-form-item field="country" label="国家">
        <a-input v-model="userInfo.country" placeholder="请输入国家" />
      </a-form-item>
      <a-form-item field="birthday" label="生日">
        <a-date-picker v-model="userInfo.birthday" style="width: 100%;" />
      </a-form-item>
      <a-form-item field="company" label="单位">
        <a-input v-model="userInfo.company" placeholder="请输入单位" />
      </a-form-item>
      <a-form-item field="companyNature" label="单位性质">
        <a-input v-model="userInfo.companyNature" placeholder="请输入单位性质" />
      </a-form-item>
      <a-form-item field="department" label="部门">
        <a-input v-model="userInfo.department" placeholder="请输入部门" />
      </a-form-item>
      <a-form-item field="duties" label="职务">
        <a-input v-model="userInfo.duties" placeholder="请输入职务" />
      </a-form-item>
      <a-form-item field="address" label="现址">
        <a-input v-model="userInfo.address" placeholder="请输入现址" />
      </a-form-item>
      <a-space align="center" direction="vertical" style="width: 100%">
        <a-button :loading="loading" html-type="submit" style="background:#4F17A8; color:#fff;">提交</a-button>
      </a-space>
    </a-form>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { getUserInfo, setUserInfo } from '@/api/login.js'
import { useStore } from 'vuex'
import { Message} from '@arco-design/web-vue'

export default {
  setup () {
    const store = useStore();
    const state = reactive({
      code: '',
      loading: false,
      userInfo: {},
      rules: {
        _nickName: [{ required: true, message: '昵称不能为空'}],
        name: [{ required: true, message: '姓名不能为空'}],
        email: [
        { required: true, message: '邮箱不能为空' },
        { match: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message: '请输入有效邮箱'}
        ],
      },
      sexList: [
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ]
    })
    // 获取
    const getInfo = (id) => {
      getUserInfo({}).then(res => {
      // getUserInfo({ id: JSON.parse(window.localStorage.getItem('webuserInfo')).id}).then(res => {

        if (res.code === 20000) {
          state.userInfo = res.result;
          state.userInfo._nickName = res.result.nickName.split('#')[0];
          state.code = res.result.nickName.split('#')[1];
        }
      })
    }
    getInfo()
    // 提交
    const formSubmit = ({values, errors}) => {
      if (errors) {
        return;
      }
      state.loading = true
      state.userInfo.id = state.userInfo.openId;
      state.userInfo.nickName = state.userInfo._nickName + '#' + state.code;
      setUserInfo(state.userInfo).then(res => {
        state.loading = false
        if (res.code === 20000) {
          const userInfo = JSON.parse(window.localStorage.getItem('webuserInfo'));
          userInfo.email = state.userInfo.email;
          userInfo.nickName = state.userInfo.nickName;
          window.localStorage.setItem('webuserInfo', JSON.stringify(userInfo));
          const storeUser = store.getters.getuser;
          storeUser.email = state.userInfo.email;
          storeUser.nickName = state.userInfo.nickName;
          store.commit('saveUser', storeUser);
          Message.success('提交成功')
        }
      })
    }
    return {
      ...toRefs(state),
      formSubmit
    }
  }
}
</script>

<style lang="less" scoped>
.course-container {
  width: 100%;
  min-height: calc(100vh - 300px);
  padding: 20px 30px 50px;
  box-sizing: border-box;
  background-color: #fff;
  .course-container-title {
    color: #323233;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
</style>
